<script>
import { scrollTo, scrollAnime } from '@/js/scrollUtils';
import TitleComp from "./TitleComp.vue";
import ButtonComp from './ButtonComp.vue';

// Import Swiper comps
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectCards } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

export default {
    name: "ProjectsComp",
    props: ['isLoading'],
    watch: {
        isLoading(val) {
            if (!val) {
                this.scrollAnime({
                    target: ".swiper-wrapper > .swiper-slide:first-child, .projects-mobile > div:nth-child(1), .projects-mobile > div:nth-child(3)",
                    direction: "left",
                    duration: 1500,
                    distance: "80px"
                });
                this.scrollAnime({
                    target: ".swiper-wrapper > .swiper-slide:nth-child(2)",
                    direction: "top",
                    duration: 1500,
                    distance: "80px"
                });
                this.scrollAnime({
                    target: ".swiper-wrapper > .swiper-slide:nth-child(3), .projects-mobile > div:nth-child(2)",
                    direction: "right",
                    duration: 1500,
                    distance: "80px"
                });
            }
        }
    },
    data() {
        return {
            modules: [EffectCards],
            darkMode: eval(localStorage.getItem("darkMode")) || false,
        };
    },
    mounted() {
        // Check if body classlist contains dark-mode
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.attributeName === "class") {
                    this.darkMode = document.body.classList.contains("dark-mode");
                }
            });
        });
        observer.observe(document.body, { attributes: true });
    },
    methods: {
        // Scroll method
        scrollTo, scrollAnime,
        toLink(link) {
            window.open(link, '_blank');
        }
    },
    components: { TitleComp, Swiper, SwiperSlide, ButtonComp },
};
</script>

<template>
    <div class="projects" id="projects">
        <TitleComp :title="$t('projects.title')" :subtitle="$t('projects.subtitle')" :isLoading="isLoading"/>
        <swiper :effect="'cards'" :grabCursor="true" :modules="modules">
            <swiper-slide :style="{ 'background-image': `url(${require('@/assets/img/projects/kograph.png')})` }">
                <div class="filter"></div>
                <div class="swiper-content">
                    <img :src="require('@/assets/img/projects/white_kograph_logo.png')"/>
                    <p>
                        {{ $t('projects.kograph.desc') }}
                    </p>
                    <ButtonComp :title="$t('projects.view')" @click="toLink('https://github.com/pabloValderasGarcia/kograph/')"/>
                </div>
            </swiper-slide>
            <swiper-slide :style="{ 'background-image': `url(${require('@/assets/img/projects/avericar.png')})` }">
                <div class="filter"></div>
                <div class="swiper-content">
                    <img :src="require('@/assets/img/projects/avericar_logo.png')"/>
                    <p>
                        {{ $t('projects.avericar.desc') }}
                    </p>
                    <ButtonComp :title="$t('projects.view')" @click="toLink('https://www.avericar.es')"/>
                </div>
            </swiper-slide>
            <swiper-slide>
                <img :src="require('@/assets/img/projects/corner.png')"/>
                <div class="swiper-coming">
                    <p>{{ $t('projects.coming.text') }}</p>
                    <ButtonComp :title="$t('projects.coming.button')" @click="scrollTo('contact', -90)"/>
                </div>
            </swiper-slide>
        </swiper>
        <div class="projects-mobile">
            <div :style="{ 'background-image': `url(${require('@/assets/img/projects/kograph.png')})` }">
                <div class="filter"></div>
                <div class="swiper-content">
                    <img :src="require('@/assets/img/projects/white_kograph_logo.png')"/>
                    <p>
                        {{ $t('projects.kograph.desc') }}
                    </p>
                    <ButtonComp :title="$t('projects.view')" @click="toLink('https://github.com/pabloValderasGarcia/kograph/')"/>
                </div>
            </div>
            <div :style="{ 'background-image': `url(${require('@/assets/img/projects/avericar.png')})` }">
                <div class="filter"></div>
                <div class="swiper-content">
                    <img :src="require('@/assets/img/projects/avericar_logo.png')"/>
                    <p>
                        {{ $t('projects.avericar.desc') }}
                    </p>
                    <ButtonComp :title="$t('projects.view')" @click="toLink('https://www.avericar.es')"/>
                </div>
            </div>
            <div>
                <img :src="require('@/assets/img/projects/corner.png')"/>
                <div class="swiper-coming">
                    <p>{{ $t('projects.coming.text') }}</p>
                    <ButtonComp :title="$t('projects.coming.button')" @click="scrollTo('contact', -90)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* SWIPER */
.swiper {
    width: 600px;
    height: 337.5px;
}
.swiper-slide {
    -webkit-box-shadow: -6px 6px 5px -3px rgba(0,0,0,0.25);
    -moz-box-shadow: -6px 6px 5px -3px rgba(0,0,0,0.25);
    box-shadow: -6px 6px 5px -3px rgba(0,0,0,0.25);
    position: relative;
    display: flex;
    border-radius: 20px;
    font-size: 22px;
    font-weight: bold;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.swiper-slide:nth-child(2) {
    background-position: center center;
}
.swiper-slide * {
    color: white !important;
}
.swiper-slide > img {
    position: absolute;
    z-index: -1;
    top: -40px;
    right: -20px;
    width: 180px;
    transform: rotate(-15deg);
}
.filter {
    background-color: rgb(0, 0, 0, .75);
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
}
body.dark-mode .filter {
    background-color: rgb(0, 0, 0, .75);
}
.swiper-content {
    padding: 40px;
}
.swiper-content p {
    margin: 20px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
}
.swiper-slide {
    background-color: #111111;
}
.swiper-coming {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.swiper-coming p {
    font-weight: 500;
    font-size: 14px;
}

/* MOBILE */
.projects-mobile {
    display: none;
    flex-direction: column;
}
.projects-mobile * {
    color: white !important;
}
.projects-mobile > div {
    position: relative;
    height: fit-content;
    background-color: #0c0c0c;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.projects-mobile > div > img {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 100px;
}
.projects-mobile .filter {
    z-index: 0 !important;
}
.projects-mobile .swiper-content {
    position: relative;
    z-index: 1;
}
.projects-mobile .swiper-coming {
    min-height: 245px;
}

/* MEDIA QUERIES */
@media screen and (max-width: 900px) {
    .swiper {
        width: 68%;
    }
}
@media screen and (max-width: 720px) {
    .swiper {
        display: none;
    }
    .projects-mobile {
        display: flex;
    }
    .swiper-content {
        padding: 50px 20px;
    }
}
</style>
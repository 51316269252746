<script>
import { scrollTo } from '@/js/scrollUtils';

export default {
    name: "NavComp",
    data() {
        return {
            darkMode: eval(localStorage.getItem("darkMode")) || false,
            currentLanguage: localStorage.getItem("language") || "ES",
            showLangDropdown: false,
            mobileMenu: false,
        };
    },
    mounted() {
        // Check dark mode
        this.checkDarkMode();
        // Check language
        this.$i18n.locale = this.currentLanguage.toLowerCase();
        // Add event so that clicking outside of a dropdown closes it
        document.addEventListener("click", this.closeDropdownOnClickOutside);
    },
    beforeUnmount() {
        // Eliminate event that when clicking outside of a dropdown closes
        document.removeEventListener("click", this.closeDropdownOnClickOutside);
    },
    methods: {
        // Scroll method
        scrollTo(target, offset) {
            this.mobileMenu = false;
            scrollTo(target, offset);
        },
        // Method to check dark mode
        checkDarkMode() {
            if (this.darkMode) {
                document.body.classList.add("dark-mode");
            } else {
                document.body.classList.remove("dark-mode");
            }
        },
        // Method to switch between dark and light mode
        toggleDarkMode() {
            // Toggle smoothly
            this.darkMode = !this.darkMode;
            localStorage.setItem("darkMode", this.darkMode);
            const allElements = document.querySelectorAll("*");
            allElements.forEach((element) => {
                // Check if the element has a background assigned
                const backgroundColor = window
                    .getComputedStyle(element)
                    .getPropertyValue("background-color");
                if (backgroundColor) {
                    element.classList.add("transitioning");
                }
            });
            this.checkDarkMode();

            // We add the transitioning class to body to see the smooth change between colors
            setTimeout(() => {
                allElements.forEach((element) => {
                    // Check if the element has a background assigned
                    const backgroundColor = window
                        .getComputedStyle(element)
                        .getPropertyValue("background-color");
                    if (backgroundColor) {
                        element.classList.remove("transitioning");
                    }
                });
            }, 200);
        },
        // Method to change language
        changeLanguage(lang) {
            this.$emit('languageChanged', lang.toLowerCase());
            this.$i18n.locale = lang.toLowerCase();
            this.currentLanguage = lang;
            localStorage.setItem("language", lang);
        },
        // Method to close dropdown when clicking outside
        closeDropdownOnClickOutside(e) {
            if (!this.$refs.nav.contains(e.target)) {
                this.showLangDropdown = false;
            }
        },
        // Method to download CV PDF
        downloadCV() {
            const timestamp = new Date().getTime();
            const pdf = `https://pablovalderas.me/pdf/${this.currentLanguage.toLowerCase()}_pablo_valderas_CV.pdf?t=${timestamp}`;
            
            // Hidden link
            const link = document.createElement('a');
            link.href = pdf;
            link.target = '_blank';
            console.log(link)

            // Add to DOM
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },
};
</script>

<template>
    <nav ref="nav" id="nav">
        <div>
            <img class="logo white-logo" alt="logo" title="pablovalderas" :src="require('@/assets/img/white_logo.png')" @click="scrollTo('hero')" v-show="this.darkMode === true" />
            <img class="logo black-logo" alt="logo" title="pablovalderas" :src="require('@/assets/img/black_logo.png')" @click="scrollTo('hero')" v-show="this.darkMode === false" />
            <ul>
                <li @click="scrollTo('bio', -90)">{{ $t("nav.bio") }}</li>
                <li @click="scrollTo('projects', -90)">{{ $t("nav.projects") }}</li>
                <li @click="scrollTo('contact', -90)">{{ $t("nav.contact") }}</li>
                <li @click="downloadCV">CV</li>
                <li class="right-nav">
                    <button type="check" class="dark-mode-toggler" @click="toggleDarkMode" :title="$t('nav.darkmode')">
                        <span></span>
                        <span></span>
                    </button>
                </li>
                <li @click="showLangDropdown = !showLangDropdown" :title="$t('nav.language')" id="language">{{ currentLanguage }}</li>
            </ul>
            <ul v-show="showLangDropdown" class="languages dropdown-container" ref="languages">
                <li @click="changeLanguage('ES')" :class="{ 'language-active': currentLanguage === 'ES' }">
                    <img src="@/assets/img/nav/es.png" />{{ $t("languages.es") }}
                </li>
                <hr />
                <li @click="changeLanguage('EN')" :class="{ 'language-active': currentLanguage === 'EN' }">
                    <img src="@/assets/img/nav/en.png" />{{ $t("languages.en") }}
                </li>
            </ul>
            <div class="nav-mobile">
                <font-awesome-icon icon="bars" @click="mobileMenu = !mobileMenu" v-show="!mobileMenu"/>
                <font-awesome-icon icon="xmark" @click="mobileMenu = !mobileMenu" v-show="mobileMenu"/>
                <ul :class="{ open: mobileMenu }">
                    <li @click="scrollTo('bio', -90)">{{ $t("nav.bio") }}</li>
                    <li @click="scrollTo('projects', -90)">{{ $t("nav.projects") }}</li>
                    <li @click="scrollTo('contact', -90)">{{ $t("nav.contact") }}</li>
                    <li @click="downloadCV">CV</li>
                    <li @click="toggleDarkMode">
                        {{ $t("nav.darkmode") }}
                        <button type="check" class="dark-mode-toggler">
                            <span></span>
                            <span></span>
                        </button>
                    </li>
                    <li @click="showLangDropdown = !showLangDropdown"><span>{{ $t("nav.language") }}:</span>{{ currentLanguage }}</li>
                </ul>
                <ul :class="{ open: mobileMenu }" v-show="showLangDropdown" class="languages dropdown-container" ref="languages">
                    <li @click="changeLanguage('ES')" :class="{ 'language-active': currentLanguage === 'ES' }">
                        <img src="@/assets/img/nav/es.png" />{{ $t("languages.es") }}
                    </li>
                    <hr />
                    <li @click="changeLanguage('EN')" :class="{ 'language-active': currentLanguage === 'EN' }">
                        <img src="@/assets/img/nav/en.png" />{{ $t("languages.en") }}
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<style>
/* NAV */
nav {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    
}
body.dark-mode nav {
    background-color: black;
}
nav > div {
    position: relative;
    display: flex;
    align-items: center;
    gap: 40px;
}
.logo {
    user-select: none;
    cursor: pointer;
    width: 40px;
}
ul {
    display: flex;
    align-items: center;
    gap: 20px;
}
ul li {
    cursor: pointer;
    font-weight: 600;
    margin: 0 auto;
    transition: color 0.3s ease 0ms;
}
ul:not(.languages) li:not(:last-child):hover {
    color: #0092fd;
}
ul li:last-child:hover {
    font-weight: bold;
}
.right-nav {
    margin-left: 200px;
}

/* NAV MOBILE */
.nav-mobile {
    display: none;
}
.nav-mobile > svg {
    width: 22px;
    font-size: 25px;
    cursor: pointer;
    transition: color 0.3s ease 0ms;
}
.nav-mobile > svg:hover {
    color: #0092fd;
}
.nav-mobile ul:not(.languages) li:not(:last-child):hover {
    color: inherit;
    font-weight: bold;
}
.nav-mobile ul:not(.languages) li:not(:last-child):hover {
    color: inherit;
    font-weight: bold;
}
.nav-mobile ul:not(.languages) {
    height: 100vh;
    position: absolute;
    width: 100%;
    right: -130%;
    top: 45px;
    display: flex;
    flex-direction: column;
    gap: 13px;
    padding: 25px;
    background-color: white;
    transition: background-color .2s ease-in-out, right .5s ease-in-out;
}
body.dark-mode .nav-mobile ul:not(.languages) {
    background-color: black;
}
.open {
    right: -20px !important;
}
.languages.open {
    right: unset !important;
    left: -5px !important;
}
.nav-mobile ul li {
    margin: unset;
    display: flex;
    align-items: center;
    gap: 10px;
}
.nav-mobile .dark-mode-toggler {
    transform: unset;
}
.nav-mobile .languages {
    width: fit-content;
    left: 130%;
    bottom: -255px;
    border-radius: 0 !important;
    transition: background-color .2s ease-in-out, left .5s ease-in-out;
}

/* DARK MODE TOGGLE */
.dark-mode-toggler {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    background: 0;
    border: 0;
    box-sizing: border-box;
}
.dark-mode-toggler:focus {
    outline: none;
}
.dark-mode-toggler span:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    background-color: #e0e0e0;
    transition: 0.3s;
}
.dark-mode-toggler span:nth-child(2) {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    transition: 0.3s;
}
body.dark-mode .dark-mode-toggler span:nth-child(1) {
    background-color: #292929;
    color: #141516;
}
body.dark-mode .dark-mode-toggler span:nth-child(2) {
    left: 25px;
}

/* CHANGE LANGUAGE */
.languages {
    position: absolute;
    bottom: -35px;
    right: 0;
    padding: 10px 15px;
    background-color: #e0e0e0;
    display: flex;
    gap: 12px;
    border-radius: 20px;
}
body.dark-mode .languages {
    background-color: #292929;
}
.languages li {
    display: flex;
    align-items: center;
    gap: 7px;
}
.languages li:hover {
    font-weight: bold;
}
.language-active {
    font-weight: bold;
}
.languages hr {
    height: auto;
    width: 2px;
    margin: 0;
    background-color: black;
    border: none;
}
body.dark-mode .languages hr {
    background-color: white;
}
.languages img {
    width: 20px;
    height: fit-content;
}

/* MEDIA QUERIES */
@media screen and (max-width: 720px) {
    nav > div {
        margin: 0 20px;
        width: 100%;
        justify-content: space-between;
    }
    nav > div > ul {
        display: none;
    }
    .nav-mobile {
        display: block;
    }
    .languages:not(.nav-mobile .languages) {
        visibility: hidden;
    }
}
</style>
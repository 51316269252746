<script>
import { scrollAnime } from '@/js/scrollUtils';
import TitleComp from './TitleComp.vue';

export default {
    name: "BiographyComp",
    data() {
        return {
            darkMode: eval(localStorage.getItem("darkMode")) || false,
        }
    },
    props: ['isLoading'],
    watch: {
        isLoading(val) {
            if (!val) {
                this.scrollAnime({
                    target: ".info > img",
                    direction: "left",
                    duration: 3000,
                    distance: "0px",
                    scale: .7,
                });
                this.scrollAnime({
                    target: ".info > div",
                    direction: "right",
                    duration: 1500,
                    distance: "80px"
                });
                this.scrollAnime({
                    target: ".signature",
                    direction: "right",
                    duration: 1500,
                    distance: "80px"
                });
            }
        }
    },
    mounted() {
        // Check if body classlist contains dark-mode
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.attributeName === "class") {
                    this.darkMode = document.body.classList.contains("dark-mode");
                }
            });
        });
        observer.observe(document.body, { attributes: true });
    },
    methods: {
        scrollAnime,
    },
    components: { TitleComp }
};
</script>

<template>
    <div class="bio" id="bio">
        <TitleComp :title="$t('bio.title')" :subtitle="$t('bio.subtitle')" :isLoading="isLoading"/>
        <div class="info">
            <img :src="require('@/assets/img/bio/me.png')"/>
            <div>
                <p>Pablo <span title="Nicolás">N.</span> Valderas García</p>
                <p v-if="this.$i18n.locale.toUpperCase() == 'ES'">
                    Nací en <span>Barcelona</span> (España) en <span>octubre de 2003</span>, y en 2009, mi familia tomó la 
                    decisión de trasladarse a <span>Granada</span> (España).
                    <br/><br/>
                    Obtuve el Grado Medio en Sistemas Microinformáticos y Redes en el 
                    Instituto Hermenegildo Lanz, y posteriormente, cursé el Grado Superior 
                    en <span>Desarrollo de Aplicaciones Web</span>, así como un Curso Especializado en 
                    <span>Inteligencia Artificial y Big Data</span> en el Instituto Zaidín Vergeles.
                    <br/><br/>
                    Mi objetivo en la vida es <span>ayudar a los demás</span>, ser feliz y tener la oportunidad de <span>viajar 
                    por el mundo</span> para crecer como persona y acumular vivencias y experiencias.
                </p>
                <p v-else>
                    I was born in <span>Barcelona</span> (Spain) in <span>October 2003</span>, and in 2009, my family decided 
                    to move to <span>Granada</span> (Spain).
                    <br/><br/>
                    I earned my Technician diploma in Microcomputer Systems and Networks from Hermenegildo Lanz Institute. 
                    Subsequently, I obtained my diploma as a <span>Web Developer</span>, along with completing a 
                    Specialized Course in <span>Artificial Intelligence and Big Data</span> at Zaidín Vergeles Institute.
                    <br/><br/>
                    My goal in life is <span>to help others</span>, be happy and have the opportunity to <span>travel around the 
                    world</span> to grow as a person and accumulate experiences.
                </p>
                <img class="signature" alt="logo" title="pablovalderas" :src="require('@/assets/img/bio/white_signature.png')" @click="scrollTo('hero')" v-if="this.darkMode === true" />
                <img class="signature" alt="logo" title="pablovalderas" :src="require('@/assets/img/bio/black_signature.png')" @click="scrollTo('hero')" v-if="this.darkMode === false" />
            </div>
        </div>
    </div>
</template>

<style scoped>
.bio {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.info {
    display: flex;
    align-items: center;
    gap: 40px;
    margin: 0 40px;
}
.info > img {
    flex: 1;
    border-radius: 20px;
    max-width: 333px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.info div {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 900px;
}
.info div p:first-child {
    font-size: 1.8em;
    font-weight: bold;
    position: relative;
}
.info div p:first-child::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 2px;
    background-color: #0092fd;
    top: -15px;
    left: 0;
    border-radius: 20px;
}
.info div p:not(:first-child) {
    font-weight: 500;
    line-height: 1.6;
}
.info div p span {
    font-weight: bold;
}
.signature {
    width: 100px;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1100px) {
    .info {
        flex-direction: column;
        align-items: flex-start;
        gap: 60px;
    }
    .info > img {
        width: 100%;
        max-width: 400px;
    }
}
@media screen and (max-width: 720px) {
    .info {
        margin: 0 20px;
    }
}
</style>
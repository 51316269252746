<script>
export default {
    name: "NotFound",
    data() {
        return {
            isDarkMode: eval(localStorage.getItem("darkMode")) || false,
            language: "ES",
            buttonText: "Volver a Inicio",
        };
    },
    mounted() {
        // Darkmode
        if (this.isDarkMode) {
            document.body.classList.add("dark-mode");
        } else {
            document.body.classList.remove("dark-mode");
        }

        // Language
        this.language = localStorage.getItem("language") || "ES";
        document.title = this.language === "ES" ? "404 No Encontrado" : "404 Not Found";
        this.buttonText = this.language === "ES" ? "Volver a Inicio" : "Back Home";
    },
    methods: {
        goHome() {
            this.$router.push("/");
        },
    },
};
</script>

<template>
    <div class="container">
        <div>
            <p>4</p>
            <img v-if="!isDarkMode" :src="require('@/assets/img/black_logo.png')" alt="Logo oscuro" />
            <img v-else :src="require('@/assets/img/white_logo.png')" alt="Logo claro" />
            <p>4</p>
        </div>
        <button @click="goHome">
            <span>{{ buttonText }}</span>
            <svg
                class="svg-inline--fa fa-angle-right"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="angle-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
            >
                <path
                    fill="currentColor"
                    d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
                />
            </svg>
        </button>
    </div>
</template>

<style scoped>
.container {
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.container div {
    display: flex;
    align-items: center;
    gap: 15px;
}

.container p {
    font-weight: bold;
    font-size: 90px;
}

button {
    position: relative;
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
    font-weight: 600;
    font-size: 16px;
    user-select: none;
    cursor: pointer;
    border: none;
    border-bottom: 2px solid;
    padding: 10px 30px 10px 10px;
    transition: padding 0.5s ease-in-out;
    background-color: transparent;
    display: flex;
    align-items: center;
}

button:hover {
    padding: 10px 42px 10px 20px;
}

button svg {
    width: 10px;
    position: absolute;
    right: 20px;
    opacity: 0;
    color: #0092fd;
    transition: all 0.5s ease-in-out;
}

button:hover svg {
    opacity: 1;
}
</style>
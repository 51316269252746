<script>
import "./assets/css/main.css";

export default {
    name: "App",
};
</script>

<template>
    <router-view />
</template>

<style>
/* APP */
#app {
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
}
</style>
<script>
import { scrollTo } from '@/js/scrollUtils';

export default {
    name: "FooterComp",
    data() {
        return {
            darkMode: eval(localStorage.getItem("darkMode")) || false,
        }
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
    },
    mounted() {
        // Check if body classlist contains dark-mode
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.attributeName === "class") {
                    this.darkMode = document.body.classList.contains("dark-mode");
                }
            });
        });
        observer.observe(document.body, { attributes: true });
    },
    methods: {
        // Scroll method
        scrollTo,
        // Open link
        toLink(link) {
            window.open(link, '_blank');
        }
    }
};
</script>

<template>
    <footer>
        <div>
            <div>
                <div class="links">
                    <p @click="scrollTo('hero')">{{ $t("footer.intro") }}</p>
                    <p @click="scrollTo('bio', -90)">{{ $t("footer.bio") }}</p>
                    <p @click="scrollTo('projects', -90)">{{ $t("footer.projects") }}</p>
                    <p @click="scrollTo('contact', -90)">{{ $t("footer.contact") }}</p>
                </div>
                <div class="icons">
                    <font-awesome-icon :icon="['fab', 'linkedin']" @click="toLink('https://www.linkedin.com/in/pvalgarn/')"/>
                    <font-awesome-icon :icon="['fab', 'github']" @click="toLink('https://github.com/pabloValderasGarcia')"/>
                </div>
            </div>
            <img class="logo white-logo" alt="logo" title="pablovalderas" :src="require('@/assets/img/white_logo.png')" @click="scrollTo('hero')" v-show="this.darkMode === true" />
            <img class="logo black-logo" alt="logo" title="pablovalderas" :src="require('@/assets/img/black_logo.png')" @click="scrollTo('hero')" v-show="this.darkMode === false" />
            <div>
                <font-awesome-icon :icon="['fab', 'linkedin']" @click="toLink('https://www.linkedin.com/in/pvalgarn/')"/>
                <font-awesome-icon :icon="['fab', 'github']" @click="toLink('https://github.com/pabloValderasGarcia')"/>
            </div>
        </div>
        <p>Copyright &copy; {{ currentYear }} Pablo Valderas</p>
    </footer>
</template>

<style scoped>
footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    background-color: #ededed;
    padding: 0 40px;
}
body.dark-mode footer {
    background-color: #111111;
}

/* FOOTER TOP */
footer > div:first-child {
    width: 100%;
    margin: 40px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 150px;
}
footer > div:first-child > div {
    flex: 1;
    display: flex;
    gap: 20px;
}
footer > div:first-child > div:first-child {
    justify-content: flex-end;
}
.links {
    display: flex;
    gap: 20px;
}
.links p {
    font-weight: 600;
}
.links p, footer svg {
    font-weight: 600;
    cursor: pointer;
    transition: color 0.3s ease 0ms;
}
.links p:hover, footer svg:hover {
    color: #0092fd;
}
footer > div:first-child > div > svg {
    font-size: 1.5em;
}
.logo {
    width: 50px;
}
.icons {
    display: none;
    gap: 10px;
}
.icons > svg {
    font-size: 1.5em;
}

/* FOOTER BOTTOM */
footer > p {
    margin: 26px 0 40px 0;
    font-weight: 500;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1050px) {
    footer {
        align-items: flex-start;
    }
    footer > div:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;
    }
    footer > div:first-child > div {
        flex-wrap: wrap;
    }
    footer > div:first-child > div:first-child {
        width: 100%;
        justify-content: space-between;
        gap: 20px;
    }
    .links {
        flex-wrap: wrap;
        gap: 10px;
    }
    footer > div:first-child > div:last-child {
        display: none;
    }
    .logo {
        order: -1;
    }
    .icons {
        display: flex;
    }
}
@media screen and (max-width: 720px) {
    footer {
        padding: 0 20px;
    }
}
</style>
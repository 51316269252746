import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import router from "./router";

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Import icons
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
library.add(faAngleRight, faGithub, faLinkedin, faXmark, faBars, faCode, faBrain, faRobot);

const i18n = createI18n({
	locale: 'es',
	messages: {
		es: require('./locales/es.json'),
		en: require('./locales/en.json'),
		// more languages...
	},
});

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).use(i18n).mount('#app');